import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApiRoutes, Enums } from "../../constants";
import { Breadcrumb, Button, InputGroup, Badge } from "react-bootstrap";
import DropDown from "../../components/common/DropDown";
import { Card } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { alertService, AlertType } from "../../services/alert.service";
import { APICall } from "../../services/axiosService";
import { commonService } from "../../services/common.service";
import DataTable from "react-data-table-component";
import InputText from "../../components/form/inputText";
import Export from "../../components/common/Export";
import NewLoader from "../../components/loader/NewLoader";

export default function Index() {

  const [Req_Params, setReqParams] = useState(null);
  const [LoaderFlag, setLoaderFlag] = useState(false);
  
  const navigate = useNavigate();
  const [pageNo, setPageNo] = useState(1);
  const [perPageRecords, setPerPageRecords] = useState(Enums.DefaultPerPageLength);
  const [incidentFrom, setIncidentFrom] = useState(null);
  const [incidentTo, setIncidentTo] = useState(null);
  const [incidentTotalRows, setIncidentTotalRows] = useState(0);
  const [incidentList, setIncidentList] = useState({
    isData: false,
    data: [],
  });
  const [dataTableLoading, setDataTableLoading] = useState(false);
  const [txtSearch, setTxtSearch] = useState("");
  const [propertyList, setPropertyList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [selectedClientList, setSelectedClientList] = useState([]);
  const [selectedPropertyList, setSelectedPropertyList] = useState([]);


  // React.useEffect(() => {
  //   getIncidentList();
  // }, [pageNo, perPageRecords]);


  useEffect(() => {
    getIncidentList("", "", 1);
  }, [txtSearch,pageNo, perPageRecords]);

  const fetchClients = async () => {
    const res = await APICall(ApiRoutes.getIncidentClients)
    if (res.status == 1) {
      setClientList(res.data.map((mapitem) => ({
        value: mapitem.client_name,
        label: mapitem.client_name,
      })))
    }
  }
  const fetchProperties = async () => {
    const res = await APICall(ApiRoutes.getIncidentProperties)

    if (res.status === 1) {
      setPropertyList(
        res.data.map((mapitem) => ({
          value: mapitem.property_name,
          label: mapitem.property_name,
        }))
      );
    }
  }
  useEffect(() => {
    // fetchClients()
    fetchProperties()
  }, []);
  async function getIncidentList(
    column = "",
    sortDirection = "",
    isFilter = 0
  ) {
    setDataTableLoading(true);

    setReqParams({
      // contract_id: contractId,
      // guard_id: guard.guard_id,
      page: pageNo,
      limit: perPageRecords,
      order: { column: column, dir: sortDirection },
      is_filter: isFilter,
      filter: {
        search_text: txtSearch,
        start_date:
          incidentFrom != null
            ? commonService.convertLocalToUTC(
                commonService.formatDate(incidentFrom) + " 00:00:00"
              )
            : null,
        end_date:
          incidentTo != null
            ? commonService.convertLocalToUTC(
                commonService.formatDate(incidentTo) + " 23:59:59"
              )
            : null,
        client_list: selectedClientList,
        property_list: selectedPropertyList,
      },
    });

    setIncidentList({ isData: false, data: [] });
    const requestMetadata = await APICall(ApiRoutes.getIncident, {
      page: pageNo,
      limit: perPageRecords,
      order: { column: column, dir: sortDirection },
      is_filter: isFilter,
      filter: {
        search_text: txtSearch,
        start_date:
          incidentFrom != null
            ? commonService.convertLocalToUTC(
              commonService.formatDate(incidentFrom) + " 00:00:00"
            )
            : null,
        end_date:
          incidentTo != null
            ? commonService.convertLocalToUTC(
              commonService.formatDate(incidentTo) + " 23:59:59"
            )
            : null,
        client_list: selectedClientList,
        property_list: selectedPropertyList
      }
    });

    if (requestMetadata) {
      setIncidentList({ isData: true, data: requestMetadata.data.list });

      setIncidentTotalRows(requestMetadata.data.total);
      setDataTableLoading(false);
    }
   
  }

  const handleSort = async (column, sortDirection) => {
    getIncidentList(column.sortField, sortDirection, 1);
  };

  const searchDateWise = (e) => {
    e.preventDefault();
    let isValidate = true;
    if (incidentTo || incidentFrom) {
      if (incidentTo && incidentFrom) {
        const dateFrom = new Date(incidentTo);
        const dateTo = new Date(incidentFrom);
        if (dateFrom > dateTo) {
          alertService.alert({
            type: AlertType.Error,
            message: "End date must be after start date",
          });
          isValidate = false;
        }
      }
    }
    if (isValidate) {
      getIncidentList("", "", 1);
      setIncidentTotalRows(1)
    }
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPageRecords(newPerPage);
  };

  const handlePageChange = (page) => {
    setPageNo(page);
  };

  const resetFilter = () => {
    setSelectedClientList([])
    setSelectedPropertyList([])
    setIncidentTo(null);
    setIncidentFrom(null);
    setTxtSearch("")
    getIncidentList("", "", 0);
  };


  let key_headers = {
    "association_name": {
      'text': "Incident By"
    },    
    "property_name": {
      'text': "Contract/Property"
    },
    "description": {
      'text': "Description"
    },
    "created_at": {
      'text': "Incident Date",
      'is_date': true,
      'date_format': "DD-MM-yyyy, hh:mm A"
    }

  }

  const incidentTblColumns = [
    {
      name: "Incident By",
      selector: (row) => row.association_type_term === Enums.UserRole.guard && row.association_name + ' ' + '(GU)'
        || row.association_type_term === Enums.UserRole.incharge && row.association_name + ' ' + '(IN)',
      sortable: true,
      sortField: "association_name",
    },
    {
      name: "Contract/Property",
      selector: (row) => <span className="cursor-pointer text-primary" onClick={() => navigate(`/contracts/view`, {
        state: {
          id: row.contract_id,
        },
      })}>{row.property_name}</span>,
      sortable: true,
      sortField: "property_name",
    },
    // {
    //   name: "Property Manager",
    //   selector: (row) => <span className="cursor-pointer text-primary" onClick={() => navigate(`/clients/view/${row.client_id}`)}>{row.client_name}</span>,
    //   sortable: true,
    //   sortField: "client_name",
    // },
    {
      name: "Description",
      selector: (row) => <span title={row.description}> {row.description}</span>,
      sortable: true,
      width: "180px",
      sortField: "description",
    },
    {
      name: "Incident Date",
      sortable: true,
      sortField: "created_at",
      cell: (row) =>
        commonService.convertUTCToLocal(row.created_at, 'DD-MM-yyyy, hh:mm A'),
    }, {
      name: "Action",
      cell: (row) => <div className="justify-content-center align-items-center d-flex">
        <Button
          style={{ fontSize: "0.7rem", padding: "0.3rem 1rem" }}
          variant="primary"
          type="button"
          onClick={() => {
            navigate(`/incidents/view/${row.incident_id}`)
          }}
        >
          View
        </Button>
      </div>

    },
  ];

  return (
    <>
      <div className="row">
        <div className="content-header row">
          <div className="content-header-left col-md-9 col-12 mb-2">
            <div className="breadcrumbs-top d-flex align-items-center">
              <h2 className="content-header-title float-start mb-0">
                Incident List
              </h2>
              <Breadcrumb className="breadcrumb-chevron">
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="/incidents">Reports</Breadcrumb.Item>
                <Breadcrumb.Item active>Incidents</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
        </div>
        <div>
          <Card>
            <Card.Body className="p-3">
              <div className="row">
                <div className="col-3">
                  <InputGroup>
                    <DatePicker
                      className="datepicker form-control"
                      selected={incidentFrom ? incidentFrom : ""}
                      dateFormat="MM-dd-yyyy"
                      placeholderText="Start Date"
                      onChange={(date) => {
                        if (date) {
                          setIncidentFrom(date);
                        } else {
                          setIncidentFrom(null);
                        }
                      }}
                    />
                  </InputGroup>
                </div>
                <div className="col-3">
                  <div>
                    <InputGroup>
                      <DatePicker
                        className="datepicker form-control"
                        selected={incidentTo ? incidentTo : ""}
                        dateFormat="MM-dd-yyyy"
                        placeholderText="End Date"
                        onChange={(date) => {
                          if (date) {
                            setIncidentTo(date);
                          } else {
                            setIncidentTo(null);
                          }
                        }}
                      />
                    </InputGroup>
                  </div>
                </div>
                {/* <div className="col-3">
                <DropDown
                  data={clientList}
                  value={selectedClientList}
                  style={{ div: "col-12" }}
                  isMulti={true}
                  onChange={(value) =>
                    setSelectedClientList(value)
                  }
                  placeholder="Select Property Manager"
                  required
                // validationText={formInputsErrors.duration_term}
                />
              </div> */}
                <div className="col-3">
                  <DropDown
                    value={selectedPropertyList}
                    data={propertyList}
                    isMulti={true}
                    style={{ div: "col-12" }}
                    onChange={(value) => setSelectedPropertyList(value)}
                    placeholder="Select Contract/Property"
                    required
                    // validationText={formInputsErrors.duration_term}
                  />
                </div>
                <div className="col-3">
                  <div className="d-flex">
                    <div className="me-2">
                      <Button
                        onClick={searchDateWise}
                        variant="outline-primary"
                        type="button"
                      >
                        <i className="fa fa-search"></i>
                      </Button>
                    </div>
                    <div className="ms-">
                      <Button
                        onClick={resetFilter}
                        variant="outline-primary"
                        type="button"
                      >
                        <i className="fa fa-refresh"></i>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>

      <div className="z-adjust-for-dropdown hideDiv  my-special-class">
        <DataTable
          paginationRowsPerPageOptions={Enums.DefaultRowsPerPageOptions}
          columns={incidentTblColumns}
          data={incidentList.data}
          progressPending={dataTableLoading}
          progressComponent={<NewLoader />}
          pagination
          paginationServer
          paginationTotalRows={incidentTotalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          onSort={handleSort}
          paginationPerPage={perPageRecords}
          actions={
            <>
              <div className="col-4">
                <InputText
                  formGroupClass={"mb-0"}
                  size={"12"}
                  type={"text"}
                  Label={false}
                  placeholder={"Search..."}
                  value={txtSearch}
                  onChange={(value) => {
                    if (value) {
                      setTxtSearch(value);
                    } else {
                      setTxtSearch(value);
                    }
                  }}
                />
              </div>
              <Export
                loaderFlag={setLoaderFlag}
                passFlag={LoaderFlag}
                data={incidentList.data}
                key_headers={key_headers}
                name={"incident"}
                requestParams={Req_Params}
                newFlage={"incidentReport"}
              />
            </>
          }
        />
      </div>
    </>
  );
}
