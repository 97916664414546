import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Form,
  Modal,
  Row,
  Spinner,
  Nav,
  Tab,
} from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useLocation, useNavigate } from "react-router-dom";
import Switch from "react-switch";
import DefaultImg from "../../assets/Images/Icon/no_image.png";
import {
  CheckpointActiveStatusEnum,
  badgesEnum,
  contractStatusEnum,
  Enums,
} from "../../constants";
import { ApiRoutes } from "../../constants/apiroutes";
import { APICall } from "../../services/axiosService";
import { commonService } from "../../services/common.service";
import AddGaurdModal from "./AddGaurdModal";
import AddInchargeModal from "./AddInchargeModal";
import AssignSchedule from "./AssignSchedule";
import NoImage from "../../assets/Images/Icon/no_image.png";
import AddCheckPointModal from "./AddCheckPointModal";
import SchedulesModal from "./SchedulesModal";
import InvoiceModal from "./InvoiceModal";
import ContractSchedules from "./ContractSchedules";
import Index from "./ReviewRating/Index";
import DeleteGuardPopup from "./DeleteGuard/DeleteGuardPopup";
import NewLoader from "../../components/loader/NewLoader";
import ExportLoader from "../../components/loader/ExportLoader";

const activate_doc_arr = ["accepted", "in_progress"];
const activate_doc_view_arr = ["pending"];

export default function Create() {
  const [TabSelected, setTabSelected] = useState("first");
  const [showModel, setShowModel] = useState(false);
  const [guard, setGuard] = useState(null);
  const [CheckpoinPdfLoading, setCheckpoinPdfLoading] = useState(false);
  const userType = JSON.parse(
    commonService.getItem("user")
  ).association_type_term;
  const [scheduleModel, setScheduleModel] = useState(false);

  const [guards, setGuards] = useState([]);

  const [selectedGuards, setSelectedGuards] = useState([]);
  const [selectedGuardsType, setSelectedGuardsType] = useState(
    Enums.GuardTypes.patrolling_guard
  );

  const [showAddGuard, setShowAddGuard] = useState(false);
  const [showAddCheckpoint, setShowAddCheckpoint] = useState(false);
  const [incharges, setIncharges] = useState([]);
  const [selectedIncahrges, setSelectedIncharges] = useState([]);
  const [showAddIncharge, setShowAddIncharge] = useState(false);
  const [showInvoiceModel, setShowInvoiceModel] = useState(false);

  const [showDeleteGuardPopup, setShowDeleteGuardPopup] = useState(false);
  const [deleteGuardDtls, setDeleteGuardDtls] = useState(null);

  const [formInputs, setInput] = useState({
    amount: "",
    new_amount: "",
    duration_title: "",
    service_hours_title: "",
    no_of_guards: "",
    no_of_checkpoints: "",
    no_of_patrolling_in_day: "",
    company_list: [],
    checkpoint_list: null,
    quote_company_id: "",
    offset: "",
  });
  const [dataTableLoading, setDataTableLoading] = useState(false);

  const navigate = useNavigate();

  const location = useLocation();

  const routeParams = location.state;

  const onSwitchToggle = async (value, item, index) => {
    const data = await APICall(ApiRoutes.liveLocationStatusUpdate, {
      id: item.contract_staff_member_id,
      is_live_location: value ? 1 : 0,
    });
    if (data) {
      setInput((prevState) => ({
        ...prevState,
        guard_list: prevState.guard_list.map((MapItem, MapIndex) => ({
          ...MapItem,
          is_live_location:
            MapIndex === index ? (value ? "1" : "0") : MapItem.is_live_location,
        })),
      }));
    }
  };

  const guardsTblColumns = [
    {
      name: "#",
      cell: (row, index, column, id) => {
        return (
          <img
            src={
              row.profile_img
                ? process.env.REACT_APP_ASSETS_URL + row.profile_img
                : DefaultImg
            }
            className="round"
            alt="user"
            width="40"
            height="40"
            style={{ objectFit: "cover" }}
          />
        );
      },
    },
    {
      name: "Guard Name",
      selector: (row) => (
        <span>
          {row.guard_name}{" "}
          {parseInt(row.is_proxy) === 1 && (
            <i class="fa fa-flag" aria-hidden="true"></i>
          )}
        </span>
      ),
      sortField: "guard_name",
    },
    {
      name: "Email",
      selector: (row) => row.guard_email,
      sortField: "guard_email",
    },
    {
      name: "Phone",
      selector: (row) => row.guard_phone,
      sortField: "guard_phone",
      width: "140px",
    },
    {
      name: "Staff Type",
      selector: (row) => (
        <span title={Enums.GuardTypesDetails[row.staff_type]}>
          {Enums.GuardTypesDetails[row.staff_type]}
        </span>
      ),
      sortField: "guard_phone",
      width: "140px",
    },
    {
      name: "ID number",
      selector: (row) => row.id_number,
      sortField: "id_number",
    },
    {
      name: "Live Location",
      selector: (row) => row.is_live_location,
      sortField: "is_live_location",
      cell: (row, index, column, id) => {
        return (
          <span className="justify-content-center align-items-center d-flex">
            <Switch
              uncheckedIcon={false}
              checkedIcon={false}
              onChange={(value) => onSwitchToggle(value, row, index)}
              checked={parseInt(row.is_live_location) === 1 ? true : false}
              height={20}
              width={40}
              onColor="#263A96"
            />
          </span>
        );
      },
    },
    {
      name: "Action",
      width: "223px",
      selector: (row) => (
        <div className="d-flex flex-column">
          <a
            className="cursor-pointer h6 mt-2"
            onClick={() => {
              setGuard(row);
              setShowModel(true);
            }}
          >
            <i className="fa fa-clock"></i> Weekly Availability
          </a>
          <a
            className="cursor-pointer h6 mt-2"
            onClick={() => {
              setGuard(row);
              setScheduleModel(true);
            }}
          >
            <i className="fa fa-clock"></i> View Schedules
          </a>
        </div>
      ),
      sortField: "id_number",
      omit: formInputs?.action_rights ? false : true,
    },
    {
      name: "",
      width: "50px",
      selector: (row) => (
        <span
          className="cursor-pointer mt-2"
          onClick={() => {
            setShowDeleteGuardPopup(true);
            setDeleteGuardDtls(row);
          }}
        >
          <i className="h6 fa fa-trash text-danger"></i>
        </span>
      ),
      sortField: "id_number",
      omit: formInputs?.action_rights ? false : true,
    },
  ];

  const inchargeTblColumns = [
    {
      name: "#",
      cell: (row, index, column, id) => {
        return (
          <img
            src={
              row.profile_img
                ? process.env.REACT_APP_ASSETS_URL + row.profile_img
                : DefaultImg
            }
            className="round"
            alt="user"
            width="40"
            height="40"
            style={{ objectFit: "cover" }}
          />
        );
      },
    },
    {
      name: "Incharge Name",
      selector: (row) => row.incharge_name,
      sortField: "incharge_name",
    },
    {
      name: "Email",
      selector: (row) => row.incharge_email,
      sortField: "incharge_email",
    },
    {
      name: "Phone",
      selector: (row) => row.incharge_phone,
      sortField: "incharge_phone",
    },
    {
      name: "ID number",
      selector: (row) => row.id_number,
      sortField: "id_number",
    },
  ];

  useEffect(() => {
    getDetails();
  }, []);
  const fetchGuardsList = async (guardsIds) => {
    let res = await APICall(ApiRoutes.guardOptions, {
      ids: guardsIds,
      is_all: false,
      remaining_guards: true,
      is_fixed_association: 1,
    });
    if (res.status == 1) {
      setGuards(res.data);
    }
  };
  const fetchInchargeList = async (inchagesIds) => {
    let res = await APICall(ApiRoutes.inchargeOptions, {
      ids: inchagesIds,
      is_all: false,
      is_fixed_association: 1,
    });
    if (res.status == 1) {
      setIncharges(res.data);
    }
  };

  const getDetails = async () => {
    setDataTableLoading(true);
    const responseData = await APICall(ApiRoutes.ContractDetails, {
      id: routeParams.id,
    });
    if (responseData) {
      // setContractId(responseData.data.contract_id);
      setInput((prevState) => ({
        ...prevState,
        ...responseData.data,
        company_list: responseData.data.companies_list,
        checkpoint_list: responseData.data.checkpoints,
      }));
      let guardsIds = [];
      let inchagesIds = [];
      responseData.data.guard_list.forEach((guardelement) => {
        guardsIds = [...guardsIds, guardelement.guard_id];
      });
      responseData.data.incharge_list.forEach((Inchargeelement) => {
        inchagesIds = [...inchagesIds, Inchargeelement.incharge_id];
      });
      fetchGuardsList(guardsIds);
      fetchInchargeList(inchagesIds);
    }
    setDataTableLoading(false);
  };

  const getLocation = () => {
    if (formInputs?.property_info?.location) {
      // const locationString = formInputs.property_info?.location;
      const value = formInputs.property_info?.location;
      let str =
        (value.address1 ? value.address1 + ", " : "") +
        (value.address2 ? value.address2 + ", " : "") +
        (value.city ? value.city + ", " : "") +
        (value.state ? value.state + ", " : "") +
        (value.country ? value.country + ", " : "") +
        (value.postal_code ? value.postal_code + ", " : "");
      str = str.trim();
      str = str.slice(0, -1);
      if (str.length && str && str !== "null") {
        return str;
      } else {
        return "-";
      }
    } else {
      return "-";
    }
  };

  const getBranchLocation = (obj) => {
    if (obj.length) {
      const locationString = obj[0];
      return (
        locationString.address1 +
        ", " +
        (locationString?.address2 ? locationString.address2 + ", " : "") +
        locationString.city +
        ", " +
        locationString.state +
        ", " +
        locationString.country +
        " - " +
        locationString.postal_code
      );
    } else {
      return "";
    }
  };

  const renderContractDetails = () => (
    <Card>
      <Card.Header>
        <h4 className="card-title">Contract Details</h4>
      </Card.Header>
      <Card.Body>
        {dataTableLoading ? (
          <NewLoader />
        ) : (
          <Row className="address">
            <Col className="col-12 col-xl-6">
              <dl className="mb-0 row">
                <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">Property:</dt>
                <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                  {formInputs?.property_info?.property_name}
                </dd>
                <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">
                  Category Type:
                </dt>
                <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                  {formInputs?.property_info?.property_category_type_title}
                </dd>
                {/* <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">
                Development Type:
              </dt>
              <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                {formInputs?.property_info?.property_development_type_title}
              </dd> */}

                <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">Duration:</dt>
                <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                  {formInputs?.duration_title}
                </dd>
                <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">
                  Service Hours:
                </dt>
                <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                  {formInputs?.service_hours_title}
                </dd>
                <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">Status:</dt>
                <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                  {formInputs?.contract_status &&
                    contractStatusEnum[formInputs.contract_status]}
                </dd>
                <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">
                  Property Location:
                </dt>
                <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                  {getLocation()}
                </dd>
              </dl>
            </Col>
            <Col className="col-12 col-xl-5">
              <dl className="mb-0 row">
                <dt className="mb-2 col-sm-12 col-md-4 col-lg-6">
                  Total Requested Guards:
                </dt>
                <dd className="mb-2 col-md-8 col-sm-8 col-lg-6">
                  {formInputs?.no_of_guards}
                </dd>

                <dt className="mb-2 col-sm-12 col-md-4 col-lg-6">
                  Total Guards:
                </dt>
                <dd className="mb-2 col-md-8 col-sm-8 col-lg-6">
                  {formInputs?.total_guard}
                </dd>

                <dt className="mb-2 col-sm-12 col-md-4 col-lg-6">
                  Total Incharge:
                </dt>
                <dd className="mb-2 col-md-8 col-sm-8 col-lg-6">
                  {formInputs?.total_incharge}
                </dd>

                <dt className="mb-2 col-sm-12 col-md-4 col-lg-6">
                  Number of patrolling in a day:
                </dt>
                <dd className="mb-2 col-md-8 col-sm-8 col-lg-6">
                  {formInputs?.no_of_patrolling_in_day}
                </dd>
                <dt className="mb-2 col-sm-12 col-md-4 col-lg-6">
                  Number of checkpoints:
                </dt>
                <dd className="mb-2 col-md-8 col-sm-8 col-lg-6">
                  {formInputs.no_of_checkpoints}
                </dd>
                {/* <dt className="mb-2 col-sm-12 col-md-4 col-lg-6">Created on:</dt>
              <dd className="mb-2 col-md-8 col-sm-8 col-lg-6">
                {formInputs?.created_at &&
                  commonService.convertUTCToLocal(formInputs?.created_at)}
              </dd> */}

                {userType != Enums.UserRole.admin && (
                  <>
                    <dt className="mb-2 col-sm-12 col-md-4 col-lg-6">
                      Amount:
                    </dt>
                    <dd className="mb-2 col-md-8 col-sm-8 col-lg-6">
                      {formInputs.total_amount}
                    </dd>{" "}
                  </>
                )}

                {/* <dt className="mb-2 col-sm-12 col-md-4 col-lg-6">Received Amount:</dt>
              <dd className="mb-2 col-md-8 col-sm-8 col-lg-6">
                {formInputs.received_amount}
              </dd> */}
                <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">Start Date:</dt>
                <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                  {formInputs?.start_date
                    ? commonService.convertUTCToLocal(
                        formInputs.start_date,
                        "DD-MM-YYYY"
                      )
                    : ""}
                </dd>
                <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">
                  Expiry Date:
                </dt>
                <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                  {formInputs?.end_date
                    ? commonService.convertUTCToLocal(
                        formInputs.end_date,
                        "DD-MM-YYYY"
                      )
                    : ""}
                </dd>
              </dl>
            </Col>
          </Row>
        )}
      </Card.Body>
    </Card>
  );

  const renderClientDetails = () => (
    <>
      {formInputs.branch_info ? (
        <Card>
          <Card.Header>
            <h4 className="card-title">Branch Details</h4>
          </Card.Header>
          <Card.Body>
            <Row className="address align-items-center">
              <Col className="col-sm-12 col-md-12 col-lg-2 col-12">
                <img
                  src={
                    formInputs.branch_info.branch_profile_img
                      ? process.env.REACT_APP_ASSETS_URL +
                        formInputs.branch_info.branch_profile_img
                      : DefaultImg
                  }
                  className={
                    "mb-3 mb-xl-0 col-8 col-sm-6 col-md-4 col-lg-12 m-auto d-block rounded"
                  }
                />
              </Col>
              <Col className="col-12 col-xl-8">
                <dl className="mb-0 row">
                  <dt className="mb-2 col-md-2 col-sm-8 col-lg-2">Name:</dt>
                  <dd
                    onClick={() => {
                      navigate("/contract/branch/profile", {
                        state: { branch_id: formInputs.branch_info.branch_id },
                      });
                    }}
                    className="mb-2 col-sm-12 col-md-10 col-lg-10 text-decoration-underline nav-link cursor-pointer mb-0 py-0"
                  >
                    {formInputs.branch_info.branch_name}
                  </dd>
                  <dt className="mb-2 col-md-2 col-sm-8 col-lg-2">Email:</dt>
                  <dd className="mb-2 col-sm-12 col-md-10 col-lg-10">
                    {formInputs.branch_info.branch_email}
                  </dd>
                  <dt className="mb-2 col-md-2 col-sm-8 col-lg-2">Phone:</dt>
                  <dd className="mb-2 col-sm-12 col-md-10 col-lg-10">
                    {formInputs.branch_info.branch_phone}
                  </dd>
                  <dt className="mb-2 col-md-2 col-sm-8 col-lg-2">Location:</dt>
                  <dd className="mb-2 col-sm-12 col-md-10 col-lg-10">
                    {getBranchLocation(
                      formInputs.branch_info.locations
                        ? formInputs.branch_info.locations.length
                          ? formInputs.branch_info.locations
                          : []
                        : []
                    )}
                  </dd>
                </dl>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      ) : (
        ""
      )}

      <Card>
        <Card.Header>
          <h4 className="card-title Property Manager Details">
            Property Manager Details
          </h4>
        </Card.Header>
        <Card.Body>
          {dataTableLoading ? (
            <NewLoader />
          ) : (
            <Row className="address align-items-center">
              {/* <Col className="col-sm-12 col-md-12 col-lg-2 col-12">
            <img
              src={
                formInputs?.client_info?.profile_img
                  ? process.env.REACT_APP_ASSETS_URL +
                  formInputs?.client_info?.profile_img
                  : DefaultImg
              }
              className={
                "mb-3 mb-xl-0 col-8 col-sm-6 col-md-4 col-lg-12 m-auto d-block rounded"
              }
            />
          </Col> */}
              <Col className="col-12 col-xl-8">
                <dl className="mb-0 row">
                  <dt className="mb-2 col-md-2 col-sm-8 col-lg-2">Name:</dt>
                  <dd
                    onClick={() => {
                      if (formInputs) {
                        if (formInputs.client_info) {
                          if (formInputs.client_info.client_id) {
                            navigate(
                              `/clients/view/${formInputs.client_info.client_id}`
                            );
                          }
                        }
                      }
                    }}
                    style={{ paddingLeft: "12px" }}
                    className="nav-link cursor-pointer pt-0  mb-0 col-sm-12 col-md-10 col-lg-10"
                  >
                    {formInputs?.client_info?.client_name}
                  </dd>
                  <dt className="mb-2 col-md-2 col-sm-8 col-lg-2">Email:</dt>
                  <dd className="mb-2 col-sm-12 col-md-10 col-lg-10">
                    {formInputs?.client_info?.email}
                  </dd>
                  <dt className="mb-2 col-md-2 col-sm-8 col-lg-2">Phone:</dt>
                  <dd className="mb-2 col-sm-12 col-md-10 col-lg-10">
                    {formInputs?.client_info?.phone}
                  </dd>
                  <dt className="mb-2 col-md-2 col-sm-8 col-lg-2">Location:</dt>
                  <dd className="mb-2 col-sm-12 col-md-10 col-lg-10">
                    {getLocation()}
                  </dd>
                  <dt className="mb-2 col-md-2 col-sm-8 col-lg-2">Position:</dt>
                  <dd className="mb-2 col-sm-12 col-md-10 col-lg-10">
                    {formInputs?.client_info?.position}
                  </dd>
                </dl>
              </Col>
            </Row>
          )}
        </Card.Body>
      </Card>
    </>
  );

  const renderGuardDetails = () => (
    <Card>
      <Card.Header>
        <h4 className="card-title">Guard Details</h4>
        {formInputs.action_rights && (
          <div>
            <button
              type="button"
              className="btn btn-primary btn-sm ms-2"
              onClick={() => setShowAddGuard(true)}
            >
              <i className="fa-solid fa-plus"></i> Select Guard
            </button>
          </div>
        )}
      </Card.Header>
      <Card.Body>
        {dataTableLoading ? (
          <NewLoader />
        ) : (
          <DataTable
          paginationRowsPerPageOptions={Enums.DefaultRowsPerPageOptions}
            columns={guardsTblColumns}
            data={formInputs.guard_list}
            progressPending={dataTableLoading}
            progressComponent={<NewLoader />}
          />
        )}
      </Card.Body>
    </Card>
  );

  const renderInchargeDetails = () => (
    <Card>
      <Card.Header>
        <h4 className="card-title">Incharge Details</h4>
        {formInputs.action_rights && (
          <button
            type="button"
            className="btn btn-primary btn-sm"
            onClick={() => setShowAddIncharge(true)}
          >
            <i className="fa-solid fa-plus"></i> Select Incharge
          </button>
        )}
      </Card.Header>
      <Card.Body>
        {dataTableLoading ? (
          <NewLoader />
        ) : (
          <DataTable
          paginationRowsPerPageOptions={Enums.DefaultRowsPerPageOptions}
            columns={inchargeTblColumns}
            data={formInputs.incharge_list}
            progressPending={dataTableLoading}
            progressComponent={<NewLoader />}
          />
        )}
      </Card.Body>
    </Card>
  );

  const renderInvoicesDetails = () => (
    <Card>
      <Card.Header>
        <h4 className="card-title">Invoices</h4>
        {formInputs.invoice_action_rights && (
          <button
            type="button"
            className="btn btn-primary btn-sm"
            onClick={() => setShowInvoiceModel(true)}
          >
            <i className="fa-solid fa-plus"></i> Send Invoice
          </button>
        )}
      </Card.Header>
      <Card.Body>
        <div className="row">
          {formInputs.invoice_list != null && !dataTableLoading ? (
            formInputs.invoice_list.length ? (
              formInputs.invoice_list.map((invoice_list, index) => {
                return (
                  <div className="col-sm-6 col-lg-4" key={index}>
                    <div className="card b-radius-10px">
                      <div className="card-body b-radius-10px p-3 d-flex border border-primary">
                        <div className="truncate w-100 d-flex justify-content-between align-items-center">
                          <h6 className="font-weight-bolder text-dark mb-0">
                            {commonService.convertUTCToLocal(
                              invoice_list.created_at
                            )}
                          </h6>
                          <a
                            target="_blank"
                            href={
                              process.env.REACT_APP_ASSETS_URL +
                              invoice_list.invoice_doc
                            }
                            className="text-primary cursor-pointer"
                          >
                            Preview Invoice
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div
                style={{ fontSize: "24px", fontWeight: "500" }}
                className="text-center text-muted"
              >
                No invoices!
              </div>
            )
          ) : (
            // <div
            //   style={{ fontSize: "24px", fontWeight: "700" }}
            //   className="text-center text-dark"
            // >
            //   Loading...
            // </div>
            <NewLoader />
          )}
        </div>
      </Card.Body>
    </Card>
  );

  const fnCheckpointPdfAPICall = () => {


    setCheckpoinPdfLoading(true)
    const endpoint = ApiRoutes.checkpointQRcodePdf;

    const requestParams = {
      contract_id: routeParams.id
    };

    // Prepare request metadata
    const requestMetadata = {
      method: "POST",
      headers: {
        ...commonService.getHeaders(),
        "Content-Type": "application/json", // Assuming JSON is expected by the API
      },
      body: JSON.stringify(requestParams), // Convert requestParams to JSON string
    };

    // Make the POST request to the API
    fetch(endpoint, requestMetadata)
      .then((response) => {
        setCheckpoinPdfLoading(false)
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then((blob) => {
        var iFrame = document.createElement("iframe");
        iFrame.src = URL.createObjectURL(blob);
        window.open(iFrame.src, "_blank");

        // document.body.appendChild(iFrame);
        // const url = window.URL.createObjectURL(blob);

        // const link = document.createElement("a");
        // link.href = url;
        // link.setAttribute("target", "_blank");
        // document.body.appendChild(link);
        // link.click();
        // link.parentNode.removeChild(link);
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  const renderCheckPointsDetails = () => (
    <Card>
      <Card.Header>
        <h4 className="card-title">Checkpoint Title</h4>
        <div>
          {/* {formInputs.checkpoint_pdf && (
            <a
              target="_blank"
              href={
                process.env.REACT_APP_ASSETS_URL + formInputs.checkpoint_pdf
              }
              className="btn me-2 btn-primary btn-sm"
            >
              <i className="fas fa-file-pdf"></i> Preview Checkpoint
            </a>
          )} */}
          {/* {formInputs.checkpoint_pdf && ( */}
            <>
              {CheckpoinPdfLoading ? <span className=" me-2 "><ExportLoader /></span> :
                <div
                  onClick={() => fnCheckpointPdfAPICall()}
                  className="btn me-2 btn-primary btn-sm"
                >

                  <i className="fas fa-file-pdf"></i> Preview Checkpoint

                </div>
              }
            </>
          {/* )} */}
          {formInputs.action_rights && (
            <>
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={() => setShowAddCheckpoint(true)}
              >
                <i className="fa-solid fa-plus"></i> Add Checkpoints
              </button>
            </>
          )}
        </div>
      </Card.Header>
      <Card.Body>
        <div className="row">
          {formInputs.checkpoint_list != null && !dataTableLoading ? (
            formInputs.checkpoint_list.length ? (
              formInputs.checkpoint_list.map((checkpointrecord, index) => {
                return (
                  <div className="col-sm-6 col-lg-4" key={index}>
                    <div className="card b-radius-10px">
                      <div className="card-body b-radius-10px p-3 d-flex border border-primary justify-content-between">
                        <div className="d-flex">
                          <span className="rounded-circle me-3">
                            <span className="b-avatar-custom">
                              <img
                                className="img-checkpoint"
                                width={"200px"}
                                height={"200px"}
                                src={`${
                                  checkpointrecord.qr_code
                                    ? process.env.REACT_APP_ASSETS_URL +
                                      checkpointrecord.qr_code
                                    : NoImage
                                }`}
                              ></img>
                            </span>
                          </span>
                          <div className="truncate">
                            <h6 className="mb-25 font-weight-bolder text-dark">
                              Title Of Checkpoint
                            </h6>
                            <span>{checkpointrecord.title}</span>
                          </div>
                        </div>

                        <span>
                          {/* {Enums.CheckPointStatus[checkpointrecord.activation_status]} */}
                          {
                            CheckpointActiveStatusEnum[
                              checkpointrecord.activation_status
                            ]
                          }
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div
                style={{ fontSize: "24px", fontWeight: "500" }}
                className="text-center text-muted"
              >
                No checkpoints!
              </div>
            )
          ) : (
            // <div
            //   style={{ fontSize: "24px", fontWeight: "700" }}
            //   className="text-center text-dark"
            // >
            //   Loading...
            // </div>
            <NewLoader />
          )}
        </div>
      </Card.Body>
    </Card>
  );

  /**
   * Create sos request
   */
  const [contractId, setContractId] = useState(null);

  // const createSosRequestFunc = async () => {
  //   let resp = await APICall(
  //     "http://qammapi.softcube.co.in/api/app/v1/contract/create_sos_request",
  //     {
  //       contract_id: "ec08f502-3a73-4c41-b59b-8a9d02aa1814",
  //       note: "Fire Emergency",
  //     }
  //   );
  //   console.log("resp>>>>>", resp);
  // };

  return (
    <>
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="breadcrumbs-top d-flex align-items-center">
            <h2 className="content-header-title float-start mb-0">
              View Contract
            </h2>
            <Breadcrumb className="breadcrumb-chevron">
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item href="/contracts">Contract</Breadcrumb.Item>
              <Breadcrumb.Item active>View</Breadcrumb.Item>
            </Breadcrumb>
            {/* <Button onClick={() => createSosRequestFunc()}>
              Create sos request
            </Button> */}
          </div>
        </div>
      </div>
      <Col className="order-0 col-12 order-lg-1 col-lg-12 col-xl-12 col">
        <Tab.Container
          onSelect={(e) => setTabSelected(e)}
          id="left-tabs-example"
          defaultActiveKey="first"
        >
          <Nav variant="pills" className="mb-3">
            <Nav.Item className=" cursor-pointer">
              <Nav.Link eventKey="first">
                <i className="fa-regular fa-th-large"></i>
                <span className="pills-tab-text">Contract Information</span>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className=" cursor-pointer">
              <Nav.Link eventKey="sec">
                <i className="fa-regular fa-clock"></i>
                <span className="pills-tab-text">Schedules</span>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className=" cursor-pointer">
              <Nav.Link eventKey="third">
                <i className="fa-regular fa-star"></i>
                <span className="pills-tab-text">Review and Rating</span>
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="first">
              {TabSelected === "first" && (
                <>
                  {renderContractDetails()}
                  {renderClientDetails()}
                  {renderGuardDetails()}
                  {renderInchargeDetails()}
                  {formInputs.invoice_action_rights && renderInvoicesDetails()}
                  {renderCheckPointsDetails()}
                </>
              )}
            </Tab.Pane>
            <Tab.Pane eventKey="sec">
              {TabSelected === "sec" && (
                <ContractSchedules contractId={routeParams.id} />
              )}
            </Tab.Pane>
            <Tab.Pane eventKey="third">
              {TabSelected === "third" && (
                <Index
                  action_right={formInputs.action_rights}
                  guardList={
                    typeof formInputs.guard_list !== "undefined"
                      ? formInputs.guard_list
                      : []
                  }
                  contractId={routeParams.id}
                />
              )}
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Col>

      <AddGaurdModal
        getDetails={getDetails}
        contractId={formInputs.contract_id}
        selectedGuards={selectedGuards}
        setSelectedGuards={setSelectedGuards}
        selectedGuardsType={selectedGuardsType}
        setSelectedGuardsType={setSelectedGuardsType}
        guards={guards}
        showAddGuard={showAddGuard}
        setShowAddGuard={setShowAddGuard}
      />
      <AddInchargeModal
        getDetails={getDetails}
        contractId={formInputs.contract_id}
        selectedIncahrges={selectedIncahrges}
        setSelectedIncharges={setSelectedIncharges}
        incharges={incharges}
        showAddIncharge={showAddIncharge}
        setShowAddIncharge={setShowAddIncharge}
      />
      <AddCheckPointModal
        getDetails={getDetails}
        contractId={formInputs.contract_id}
        showAddCheckpoint={showAddCheckpoint}
        setShowAddCheckpoint={setShowAddCheckpoint}
      />
      {showModel && (
        <AssignSchedule
          getDetails={getDetails}
          setShowModel={setShowModel}
          guard={guard}
          contractId={formInputs.contract_id}
          offset={formInputs.offset}
          showModel={showModel}
          setGuard={setGuard}
        />
      )}
      {scheduleModel && (
        <SchedulesModal
          scheduleModel={scheduleModel}
          setGuard={setGuard}
          guard={guard}
          offset={formInputs.offset}
          setScheduleModel={setScheduleModel}
        />
      )}

      <InvoiceModal
        showInvoiceModel={showInvoiceModel}
        contractId={formInputs.contract_id}
        setShowInvoiceModel={setShowInvoiceModel}
        getDetails={getDetails}
      />

      <DeleteGuardPopup
        getDetails={getDetails}
        deleteGuardDtls={deleteGuardDtls}
        setDeleteGuardDtls={setDeleteGuardDtls}
        showDeleteGuardPopup={showDeleteGuardPopup}
        setShowDeleteGuardPopup={setShowDeleteGuardPopup}
      />
    </>
  );
}
